.bg-color {
  background: #bd054a;
}
.bg-color2 {
  background: #40191f;
}
.color {
  color: #bd054a;
}
.color2 {
  color: #40191f;
}
a:hover,
a:focus {
  color: #c9141b;
}
.top-nav a:hover {
  color: #bd054a;
}
.language-link:hover {
  color: #bd054a;
}
.currency-link:hover {
  color: #bd054a;
}
.mini-cart-icon {
  color: #bd054a;
}
.mini-cart-title > a:hover {
  color: #bd054a;
}
.mini-cart-price {
  color: #bd054a;
}
.mini-cart-button a:hover {
  background: #c1272d none repeat scroll 0 0;
}
.main-nav > ul > li:hover > a {
  color: ##c1272d;
}
.sub-menu > li:hover > a {
  color: #bd054a;
}
.search-form::after {
  color: #bd054a;
}
.sidebar-mega-menu a:hover {
  color: #bd054a;
}
.product-title > a:hover {
  color: #bd054a;
}
.product-price > strong {
  color: #c1272d;
}
.mega-menu-v2 .sidebar-mega-menu a:hover {
  color: #bd054a;
}
.mega-menu-v2 .shopnow:hover {
  color: #bd054a;
}
.directnav-slider:hover .owl-theme .owl-controls .owl-buttons div:hover {
  background: #bd054a;
}
.banner-info-inner > label {
  color: #bd054a;
}
.item-special-adv.style1 {
  background: #bd054a none repeat scroll 0 0;
}
.item-special-adv.style1::before {
  background: rgba(189, 5, 74, 0.5);
}
.item-special-adv.style1::after {
  background: rgba(189, 5, 74, 0.5);
}
.item-category-home:hover h3 a {
  color: #bd054a;
}
.title-tab-home > ul > li.active a,
.title-tab-home > ul > li:hover > a {
  background: #bd054a none repeat scroll 0 0;
  border: 1px solid #bd054a;
}
.item-home-bestsell .product-extra-link a:hover {
  color: #bd054a;
}
.item-home-bestsell .product-extra-link a.addcart-link {
  color: #bd054a;
}
.deal-week-product .product-title a:hover {
  color: #bd054a;
}
.product-more-link a:hover {
  background-color: #bd054a;
}
.content-deal-countdown .time_circles > div::after {
  border: 2px solid #bd054a;
}
.staff-info h3 a {
  color: #bd054a;
}
.news-info li a.more:hover {
  color: #bd054a;
  border-color: #bd054a;
}
.news-info h3 a:hover {
  color: #bd054a;
}
.list-download a:hover {
  color: #bd054a;
}
.review-content > h3 a {
  color: #bd054a;
}
.review-content > p::before {
  color: #bd054a;
}
.review-content > p::after {
  color: #bd054a;
}
.customer-thumb > a.active {
  border-color: #bd054a;
  box-shadow: 0 0 0 2px #bd054a;
}
.customer-thumb > a.active::before {
  background: #bd054a none repeat scroll 0 0;
}
.footer-box .readmore {
  color: #bd054a;
}
.social-footer > a:hover {
  color: #fff;
  background: #bd054a;
  border-color: #bd054a;
}
.copyright > a {
  color: #bd054a;
}
.footer-menu a:hover {
  color: #bd054a;
}
.newsletter-form input[type="submit"]:hover {
  background-color: #bd054a;
}
.footer-email > a:hover {
  color: #bd054a;
}
.footer-box a:hover {
  color: #bd054a;
}
.title-footer span {
  color: #bd054a;
}
.widget.widget-shop-cat > ul > li > ul > li > a:hover {
  color: #bd054a;
}
.attr-filter.attr-filter-price input {
  color: #bd054a;
}
.attr-filter.attr-filter-size li.active a {
  background: #bd054a;
}
.grid-view-2-col .quick-link:hover {
  background-color: #bd054a;
}
.widget.widget-manufacturer a:hover {
  color: #bd054a;
}
.wd-adv-info h3 a:hover {
  color: #bd054a;
}
.wd-adv-info .shopnow:hover {
  background-color: #bd054a;
}
.product-extra-link.style2 a:hover {
  color: #bd054a;
}
.product-extra-link.style2 a.addcart-link:hover {
  background: #c1272d;
}
.list-view .quick-link:hover {
  background: #bd054a;
}
.detail-gallery .bx-controls-direction a:hover {
  color: #bd054a;
}
.share-social-product:hover .share-link {
  color: #bd054a;
}
.product-detail-extra-link a:hover {
  color: #bd054a;
}
.title-tab-detail li.active a,
.title-tab-detail li:hover a {
  color: #bd054a;
}
.latest-product-detail .owl-theme .owl-controls .owl-buttons div:hover {
  color: #bd054a;
}
.gallery-control a:hover {
  background: #bd054a;
}
.title-tab-detail.style4 li.active a {
  border-color: #bd054a;
}
.post-title > a:hover {
  color: #bd054a;
}
.readmore-comment-post a:hover {
  color: #bd054a;
}
.widget-blog-cat ul li a:hover {
  color: #bd054a;
}
.post-format-date .post-date {
  background: #bd054a none repeat scroll 0 0;
}
.post-info-total > li:last-child a {
  color: #bd054a;
}
.post-info-total > li a:hover {
  color: #bd054a;
}
.post-medium-item .post-info-total > li a:hover,
.post-medium-item .post-info-total > li:last-child a {
  color: #bd054a;
}
.post-medium-item .post-readmore:hover {
  color: #bd054a;
}
.main-content-single .block-quote::before {
  background-color: #bd054a;
}
.unorder-list li a:hover {
  color: #bd054a;
}
.single-post-tabs > a:hover {
  color: #bd054a;
}
.single-post-social > a:hover {
  background: #bd054a;
  border-color: #bd054a;
}
.post-author-info li a:hover {
  color: #bd054a;
}
.single-related-post-slider .owl-theme .owl-controls .owl-buttons div:hover {
  background-color: #bd054a;
}
.header-post-comment .leave-comment-link:hover {
  color: #bd054a;
}
.header-post-comment .leave-comment-link:hover::before {
  border-color: #bd054a;
}
.reply-comment-link:hover {
  color: #bd054a;
}
.post-date-author > li a:hover {
  color: #bd054a;
}
.form-contact input[type="submit"]:hover {
  background: #bd054a none repeat scroll 0 0;
}
.single-post-control a:hover {
  background-color: #bd054a;
}
.shop-cat-info .shopnow:hover {
  background: #bd054a;
}
.shop-cat-info > h3 a:hover {
  color: #bd054a;
}
.price-cart-top {
  color: #bd054a;
}
.btn-eye {
  background: #bd054a none repeat scroll 0 0;
}
.banner-home2 .owl-theme .owl-controls .owl-buttons div:hover {
  background: #bd054a none repeat scroll 0 0;
}
.cat-mask-icon {
  background: #bd054a none repeat scroll 0 0;
}
.bestsell-tab2 li.active a {
  color: #bd054a;
}
.bestsale-filter .toggle-filter:hover {
  color: #bd054a;
}
.item-box-filter.box-tags-filter a:hover {
  background: #bd054a;
}
.item-box-filter a:hover {
  color: #bd054a;
}
.item-full-collect:hover .product-title a {
  color: #bd054a;
}
.nec-why-ifo .btn-eye:hover {
  background: #bd054a;
}
.title-deal-day {
  background: #bd054a none repeat scroll 0 0;
}
.product-saleoff > strong {
  color: #bd054a;
}
.product-saleoff > strong {
  color: #bd054a;
}
.dealday-countdown .time_circles > div {
  background: #bd054a none repeat scroll 0 0;
}
.inner-header-contact3 .language-link:hover {
  color: #bd054a;
}
.inner-header-contact3 .currency-link:hover {
  color: #bd054a;
}
.contact-box > p span.lnr {
  color: #bd054a;
}
.adv-info3 .btn-eye.black-eye:hover {
  background: #bd054a;
}
.adv-info3 .readmore:hover {
  color: #bd054a;
}
.adv-info3 .btn-eye:hover {
  background: #bd054a;
}
.loadmore-item > a:hover {
  color: #bd054a;
}
.item-from-blog3 .post-title > a:hover {
  color: #bd054a;
}
.list-instagram a::after {
  background: rgba(189, 5, 74, 0.5);
}
.footer.footer3 .footer-box .readmore {
  color: #bd054a;
}
.footer.footer3 .footer-box a:hover {
  color: #bd054a;
}
.footer.footer3 .social-footer > a:hover {
  background: #bd054a;
}
.footer.footer3 .footer-menu a:hover {
  color: #bd054a;
}
.footer.footer3 .newsletter-form input[type="submit"]:hover {
  background: #bd054a;
}
.post-blog-info3 a:hover {
  color: #bd054a;
}
.mini-cart3 .price-cart-top span {
  color: #bd054a;
}
.header-intro .lnr {
  color: #bd054a;
}
.top-info4 li .lnr {
  color: #bd054a;
}
.main-header4 {
  background: #bd054a none repeat scroll 0 0;
}
.smart-search {
  background: #bd054a none repeat scroll 0 0;
}
.content-category-dropdown > ul {
  border: 2px solid #bd054a;
}
.content-category-dropdown > ul > li.has-cat-mega .cat-mega-menu {
  border: 2px solid #bd054a;
}
.content-category-dropdown > ul > li.has-cat-mega.active > a::before {
  background: #bd054a;
}
.category-dropdown-link::before,
.category-dropdown-link::after {
  color: #bd054a;
}
.mini-cart4 .price-cart-top span {
  color: #bd054a;
}
.main-nav.main-nav4 > ul > li:hover > a {
  color: #bd054a;
}
.btn-eye.btn-eye4:hover {
  background: #bd054a none repeat scroll 0 0;
  border: 1px solid #bd054a;
}
.content-category-dropdown > ul > li.active > a {
  color: #bd054a;
}
.adv-slider4 .owl-theme .owl-controls .owl-buttons div:hover {
  background: #bd054a;
}
.product-item4:hover .product-title a {
  color: #bd054a;
}
.product-item4 .product-more-link4 a:hover {
  background: #bd054a;
}
.video-best-info::before {
  background: #bd054a none repeat scroll 0 0;
}
.bestlase-slider4 .owl-theme .owl-controls .owl-buttons div:hover {
  background: #bd054a;
}
.tab-cat-header4 li.active a {
  color: #bd054a;
}
.tab-cat-slider4 .owl-theme .owl-controls .owl-buttons div:hover {
  background: #bd054a;
}
.top-save-accor h2 span {
  background: #bd054a none repeat scroll 0 0;
}
.banner-slider5 .owl-theme .owl-controls .owl-page.active span {
  background: #bd054a;
}
.special-intro-info > h3 {
  color: #bd054a;
}
.special-intro-info > p::before {
  background: #bd054a none repeat scroll 0 0;
}
.special-product-info {
  border: 1px solid #bd054a;
}
.item-special-product .product-title > a {
  color: #bd054a;
}
.special-product-gallery .gallery-slider::before {
  background: #bd054a none repeat scroll 0 0;
}
.header-nav6 {
  border-top: 2px solid #bd054a;
}
.search-form.search-form6 input[type="submit"] {
  background: #bd054a none repeat scroll 0 0;
}
.header-link6 > a .lnr {
  color: #bd054a;
}
.main-nav.main-nav6 > ul > li:hover > a::before {
  background: #bd054a;
}
.main-nav.main-nav6 > ul > li:hover > a {
  color: #bd054a;
}
.mini-cart6 .mini-cart-link {
  background: #bd054a none repeat scroll 0 0;
}
.banner-slider6 .flex-direction-nav a:hover {
  color: #bd054a;
}
.banner-slider6 .banner-control .flexslider .flex-active-slide a::before {
  border: 3px solid #bd054a;
}
.item-product6 .qickview-link {
  color: #bd054a;
}
.item-product6:hover .qickview-link:hover {
  background: #bd054a;
}
.item-product6:hover .product-title a,
.item-product6:hover .product-add-cart .addcart-link {
  color: #bd054a;
}
.intro-featured6 .btn-eye:hover {
  background: #bd054a none repeat scroll 0 0;
}
.inner-cat-content .cat-title a:hover {
  color: #bd054a;
}
.inner-cat-content .product-title a:hover {
  color: #bd054a;
}
.main-nav.main-nav7 .icon-main-nav7:hover,
.main-nav.main-nav7 .close-main-nav7:hover {
  color: #bd054a;
}
.main-nav.main-nav7 > ul > li:hover > a {
  color: #bd054a;
}
.profile-box7 .profile-link {
  color: #bd054a;
}
.main-nav-hover .icon-menu-hover {
  color: #bd054a;
}
.box-search-hover .icon-search-hover {
  color: #bd054a;
}
.btn-eye.btn-black:hover {
  background: #bd054a;
}
.directnav-slider.banner-slider7 .owl-theme .owl-controls .owl-buttons div:hover {
  color: #bd054a;
}
.btn-eye.eye-white:hover {
  background: #bd054a none repeat scroll 0 0;
  border: 1px solid #bd054a;
}
.item-product7 .qickview-link {
  color: #bd054a;
}
.blog-info-extra a:hover {
  color: #bd054a;
}
.blog-info-extra a .lnr {
  color: #bd054a;
}
.product-slider7 .owl-theme .owl-controls .owl-buttons div:hover {
  background: #bd054a;
}
.main-nav.main-nav8 > ul > li:hover > a {
  color: #bd054a;
}
.product-slider8 .owl-theme .owl-controls .owl-buttons div:hover {
  background: #bd054a;
}
.directnav-slider.latest-slider8 .owl-theme .owl-controls .owl-buttons div:hover {
  background: #bd054a;
}
.product-tab-title9 > ul li.active a {
  background: #bd054a none repeat scroll 0 0;
}
.banner-slider9 .owl-controls {
  background: #bd054a none repeat scroll 0 0;
}
.extra-link10 a:hover {
  border-color: #bd054a;
}
.load-more10:hover {
  border-color: #bd054a;
  background: #bd054a;
  color: #fff;
}
.slider-home11 .owl-theme .owl-controls .owl-buttons div:hover {
  color: #bd054a;
}
.item-cat-brow:hover .cat-brow-info h3 a {
  color: #bd054a;
}
.item-product11 .product-extra-link a:hover {
  color: #bd054a;
}
.item-product11 .product-thumb .btn-eye:hover {
  background: #bd054a;
}
.from-blog-slider11 .owl-theme .owl-controls .owl-buttons div:hover {
  color: #bd054a;
}
.item-product-type .quickview-link:hover {
  background: #bd054a;
}
.main-nav.main-nav12 > ul > li:hover > a {
  color: #bd054a;
}
.search-form.search-form12::after {
  color: #bd054a;
}
.inner-banner-info12 > h2 {
  background: #bd054a none repeat scroll 0 0;
}
.banner-slider12 .owl-theme .owl-controls .owl-page.active span,
.banner-slider12 .owl-theme .owl-controls .owl-page span:hover {
  background: #bd054a;
}
.item-product11.style2 .product-extra-link a.btn-eye:hover {
  background: #40191f;
  border-color: #40191f;
}
.product-tab-title12 a:hover,
.product-tab-title12 li.active a {
  background: #bd054a;
}
.item-product12 .quickview-link {
  color: #bd054a;
}
.item-product12 .quickview-link:hover {
  background: #bd054a;
}
.product-tab-slider12 .owl-theme .owl-controls .owl-buttons div:hover {
  background: #bd054a;
}
.about-menu a::after {
  background: #bd054a none repeat scroll 0 0;
}
.about-menu a:hover {
  color: #bd054a;
}
.item-contact-info .contact-icon:hover::before {
  color: #bd054a;
}
.item-contact-info .contact-icon:hover i {
  color: #bd054a;
}
.item-contact-info a:hover {
  color: #bd054a;
}
.about-full-protec span {
  background: #bd054a none repeat scroll 0 0;
}
.about-full-protec .new-user-guide:hover {
  color: #bd054a;
}
.about-review-thumb a:hover {
  border-color: #bd054a;
}
.contact-form-page .form-contact input[type="submit"]:hover {
  background: #bd054a none repeat scroll 0 0;
}
.form-my-account input[type="submit"]:hover {
  background: #bd054a;
}
.form-my-account a:hover {
  color: #bd054a;
}
.general-setting .btn-setting:hover {
  color: #bd054a;
}
.layout-setting-box li.active a {
  background: #bd054a;
}
.search-form.search-form3 {
  background: #bd054a none repeat scroll 0 0;
}
.main-nav.main-nav3 > ul > li.current-menu-item > a {
  background: #bd054a none repeat scroll 0 0;
}
.news-info a.readmore:hover {
  color: #bd054a;
  border-color: #bd054a;
}
.all-staff {
  background-color: #bd054a;
}
.list-brand > ul::after {
  background-color: #bd054a;
}
.item-home-bestsell .product-extra-link a::after {
  background-color: #bd054a;
}
.list-download a:hover::before {
  background-color: #bd054a;
  border-color: #bd054a;
}
.special-product-info .product-extra-link.style2 a.btn-eye:hover {
  background: #bd054a;
}
.special-product-gallery .gallery-control a::after {
  background: #bd054a none repeat scroll 0 0;
}
.main-nav.main-nav8 .toggle-mobile-menu::after {
  color: #bd054a;
}
.item-product8 .product-extra-link > a > span::after {
  border-bottom: 6px solid #bd054a;
}
.item-product8 .product-extra-link > a > span {
  background: #bd054a none repeat scroll 0 0;
}
.mini-cart1 .mini-cart-number {
  background: #bd054a;
}
.inner-banner-info1 > h2 span {
  background: rgba(189, 5, 74, 0.9);
}
.owl-theme .owl-controls .owl-page.active span {
  background: #bd054a;
}
.btn-eye.bg-white::before {
  color: #bd054a;
}
.btn-eye.bg-white:hover {
  background: #bd054a;
  border-color: #bd054a;
  color: #fff;
}
.btn-eye.bg-white:hover::before {
  color: #fff;
}
.title-underline span {
  border-bottom: 1px solid #bd054a;
}
.banner-adv1 .banner-info p {
  margin: 10px 0 23px;
}
.item-techpro.table::before {
  border: 1px dashed #bd054a;
}
.item-product10 .product-info::before {
  background: #bd054a;
}
.mini-cart2 .mini-cart-icon {
  background: #bd054a;
}
.extra-link10 a {
  color: #bd054a;
}
.extra-link10 a:hover {
  border-color: #bd054a;
  color: #fff;
  background: #bd054a;
}
.btn-eye.eye-white::before {
  color: #bd054a;
}
.newsletter-form input[type="submit"].bg-color {
  background: #bd054a;
}
.back-to-top {
  background: #40191f;
  border: 2px solid #bd054a;
  color: #40191f !important;
}
.back-to-top::after {
  background: #bd054a;
}
.title-tab2 ul li.active a {
  background: #40191f;
  color: #bd054a;
}
.owl-theme.arrow-style2 .owl-controls .owl-buttons div {
  background: #40191f;
}
.owl-theme.arrow-style2 .owl-controls .owl-buttons div:hover {
  background: #bd054a;
}
.footer2.footer .title-footer,
.footer2.footer .title-footer span {
  color: #40191f;
}
.footer.footer2 .newsletter-form > label {
  color: #40191f;
}
.footer.footer2 .footer-box a:hover {
  color: #bd054a;
}
.footer.footer2 .footer-box .readmore {
  color: #40191f;
}
.footer.footer2 .footer-box .readmore:hover {
  color: #bd054a;
}
.footer.footer2 .newsletter-form input[type="submit"] {
  background: #40191f;
}
.footer.footer2 .newsletter-form input[type="submit"]:hover {
  background: #bd054a;
}
.footer.footer2 .copyright > a {
  color: #40191f;
}
.footer.footer2 .copyright > a:hover {
  color: #bd054a;
}
.footer.footer2 .footer-menu a:hover {
  color: #bd054a;
}
.footer.footer2 .back-to-top {
  border-color: #40191f;
  background: #fff;
}
.footer.footer2 .back-to-top::after {
  color: #bd054a;
  background: #40191f;
}
.access4.style-dark .access-info > span {
  color: #bd054a;
}
.access4.style-dark li:hover {
  background: #bd054a;
}
.adv-info3 .btn-eye.bg-color2 {
  background: #40191f;
  color: #fff;
}
.adv-info3 .btn-eye.bg-color2:hover {
  background: #bd054a;
}
.top-header5 .top-info3 li a:hover {
  color: #bd054a;
}
.btn-eye.bg-color:hover {
  background: #40191f;
}
.main-nav.main-nav5 > ul > li:hover > a {
  color: #40191f;
}
.search-form.search-form5.search-form2::after {
  color: #bd054a;
}
.inner-banner-info5 .btn-eye.bg-white::before {
  color: #40191f;
}
.title-tab5 ul li.active a {
  color: #fff;
  background: #bd054a;
  border-color: #bd054a;
}
.footer.footer2.footer4 .copyright > a {
  color: #bd054a;
}
.footer.footer2.footer4 .newsletter-form input[type="submit"]:hover {
  background: #bd054a;
}
.footer.footer2.footer4 .title-footer span {
  color: #bd054a;
}
.footer.footer2.footer4 .footer-box .readmore {
  color: #bd054a;
}
.info-phone6 .btn-eye.bg-white:hover {
  background: #40191f;
}
.item-product10.style2:hover .product-info::before {
  background: #bd054a;
}
.banner-adv.banner-ads6 .banner-info h2.title48 {
  border-bottom: 1px solid #bd054a;
}
.social-nav4 a:hover {
  background-color: #bd054a;
  border-color: #bd054a;
}
.info-cart4 > li > a > span {
  color: #bd054a;
}
.info-cart4 .mini-cart1 .mini-cart-icon {
  color: #bd054a;
}
.item-service4.table .service-icon a {
  border: 1px dashed #bd054a;
  color: #bd054a;
}
.item-service4.table .service-icon a:hover {
  background: #bd054a;
  color: #fff;
}
.smart-search4 .smart-search-form::after {
  color: #bd054a;
}
@media (max-width: 767px) {
  .main-nav.main-nav3 .toggle-mobile-menu span,
  .main-nav.main-nav3 .toggle-mobile-menu::before,
  .main-nav.main-nav3 .toggle-mobile-menu::after {
    background: #bd054a;
  }
}
